import { createWebHistory, createRouter } from 'vue-router'

// Page
import LandingPage from '../views/LandingPage.vue'
import Dashboard from '../views/Dashboard.vue'
import TambahPemeriksaan from '../views/TambahPemeriksaan.vue'
import HasilPemeriksaan from '../views/HasilPemeriksaan.vue'
import DetailPemeriksaan from '../views/DetailPemeriksaan.vue'
import IsiPemeriksaan from '../views/IsiPemeriksaan.vue'
import PageError from '../views/PageError.vue'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "landingPage",
    component: LandingPage
  },
  {
    path: "/homeView",
    name: "homeView",
    component: HomeView
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard
  },
  {
    path: "/tambahPemeriksaan",
    name: "tambahPemeriksaan",
    component: TambahPemeriksaan,
    props: true,
  },
  {
    path: "/hasilPemeriksaan",
    name: "hasilPemeriksaan",
    component: HasilPemeriksaan
  },
  {
    path: "/detailPemeriksaan/:growthMeasurementId",
    name: "detailPemeriksaan",
    component: DetailPemeriksaan
  },
  {
    path: "/isiPemeriksaan",
    name: "isiPemeriksaan",
    component: IsiPemeriksaan
  },
  {
    path: '/:catchAll(.*)*',
    name: "PageError",
    component: PageError,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
 
export default router