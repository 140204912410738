<template>
    <div class="wrapper">
        <div class="wrapperHeader">
            <div class="wrapIconHeader" @click="$router.go(-1)">
                <font-awesome-icon icon="chevron-left" />
            </div>
            <h3>
                Hasil Pemeriksaan
            </h3>
        </div>
    </div>
    <div class="header">
        <div class="rowHeaderWrap">
            <div class="rowHeaderWrapImage">
                <v-img src="@/assets/default_profile.png" class="imgCenter" alt="" cover>
                </v-img>
            </div>
            <div class="rowHeaderWrapContent">
                <li>
                    <h4>{{ this.fullName }}</h4>
                    <p v-if="this.gender == 'FEMALE'">Perempuan <font-awesome-icon icon="circle" class="icon-grey-small" /> {{ this.tglLahir }}</p>
                    <p v-else>Laki-Laki <font-awesome-icon icon="circle" class="icon-grey-small" /> {{ this.tglLahir }}</p>
                </li>
            </div>
        </div>
    </div>
    <div class="tabWrapper">
        <div :class="{'backgroundTabActive': flagActive == 0, 'backgroundTab': flagActive != 0}" @click="changeFlagActive(0)">
            <h3>Hasil</h3>
        </div>
        &nbsp;
        <div :class="{'backgroundTabActive': flagActive == 1, 'backgroundTab': flagActive != 1}" @click="changeFlagActive(1)">
            <h3>PB/U</h3>
        </div>
        &nbsp;
        <div :class="{'backgroundTabActive': flagActive == 2, 'backgroundTab': flagActive != 2}" @click="changeFlagActive(2)">
            <h3>BB/U</h3>
        </div>
        &nbsp;
        <div :class="{'backgroundTabActive': flagActive == 3, 'backgroundTab': flagActive != 3}" @click="changeFlagActive(3)">
                <h3>{{ this.totalMonths <= 24 ? 'BB/PB' : 'BB/TB' }}</h3>
            </div>
        &nbsp;
        <div :class="{'backgroundTabActive': flagActive == 4, 'backgroundTab': flagActive != 4}" @click="changeFlagActive(4)">
            <h3>KMS</h3>
        </div>
    </div>

    <div class="buttonWrapper" style="display: flex; justify-content: center;" v-if="this.isShowFilterBbTb">
        <button class="customButton" style="margin-right: 30px; font-size: 14px;" @click="handleFilterClick(0)" :style="{ color: this.activeButton === 0 ? '#2B92E4' : 'black' }">
            <u><b>0-24 BLN</b></u>
        </button>
        <button class="customButton" style="font-size: 14px;" @click="handleFilterClick(1)" :style="{ color: this.activeButton === 1 ? '#2B92E4' : 'black' }">
            <u><b>25-60 BLN</b></u>
        </button>
    </div>

    <div class="dataSection" style="overflow-x: auto;">
        <div class="infinite-scroll-list" ref="scrollContainer" 
            @scroll="handleScroll" v-if="flagActive == 0">
            <ul>
                <li v-for="data in dataHasil" :key="data.measurementDate" style="list-style: none;">
                    <div class="backgroundRowData" @click="redirectToDetail(data)">
                        <p style="color: black;">
                            {{ this.formatedDate(data.measurementDate) }}
                        </p>
                        <v-divider style="margin-top: 5px; margin-bottom: 10px; 
                            color: grey;"></v-divider>
                        <table class="dataTable">
                            <tr>
                                <td style="color: grey; width: 100px;">Usia</td>
                                <td>
                                    <h3 v-if="data.age.years > 0 && data.age.totalMonths > 0 && data.age.months > 0">{{ data.age.totalMonths }} Bulan atau {{ data.age.years }} Tahun {{ data.age.months }} Bulan</h3>
                                    <h3 v-else-if="data.age.years > 0 && data.age.totalMonths > 0 && data.age.months <= 0">{{ data.age.totalMonths }} Bulan atau {{ data.age.years }} Tahun</h3>
                                    <h3 v-else>{{ data.age.totalMonths }} Bulan</h3>
                                </td>
                                <td rowspan="7">
                                    <font-awesome-icon icon="chevron-right" class="colorIcon" />
                                </td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px;">Berat Badan</td>
                                <td><h3>{{ data.weightBodyKg }} kg</h3></td>
                            </tr>
                            <tr v-if="data.age.totalMonths > 24">
                                <td style="color: grey; width: 100px;">Tinggi Badan</td>
                                <td>
                                    <h3>{{ data.heightBodyCm }} cm</h3>
                                </td>
                            </tr>
                            <tr v-else>
                                <td style="color: grey; width: 100px;">Panjang Badan</td>
                                <td>
                                    <h3>{{ data.heightBodyCm }} cm</h3>
                                </td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px; font-weight: bold;">Indeks Gizi:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px;">BB/U</td>
                                <td>
                                    <h3 style="color: #18AF7C;" v-if="data.bbPerUmNutritionalStatusCategory == 'BB Normal'">
                                        {{ data.bbPerUmNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #FE9800;" v-else-if="data.bbPerUmNutritionalStatusCategory == 'BB Kurang'">
                                        {{ data.bbPerUmNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #D3555C;" v-else>
                                        {{ data.bbPerUmNutritionalStatusCategory }}
                                    </h3>
                                </td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px;">PB/U</td>
                                <td>
                                    <h3 style="color: #18AF7C;" v-if="data.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Normal' || data.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Tinggi'">
                                        {{ data.pbPerUmOrTbPerUmNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #FE9800;" v-else-if="data.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Pendek'">
                                        {{ data.pbPerUmOrTbPerUmNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #D3555C;" v-else>
                                        {{ data.pbPerUmOrTbPerUmNutritionalStatusCategory }}
                                    </h3>
                                </td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px;">BB/PB</td>
                                <td>
                                    <h3 style="color: #18AF7C;" v-if="data.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Baik'">
                                        {{ data.bbPerPbOrBbPerTbNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #FE9800;" v-else-if="data.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Kurang' || data.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Lebih' || data.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Beresiko Gizi Lebih'">
                                        {{ data.bbPerPbOrBbPerTbNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #D3555C;" v-else>
                                        {{ data.bbPerPbOrBbPerTbNutritionalStatusCategory }}
                                    </h3>
                                </td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px;">N/T</td>
                                <td v-if="data.growthStatus == 'Naik'"><h3 style="color: #18AF7C;">{{ data.growthStatus }}</h3></td>
                                <td v-else><h3 style="color: #D3555C;">{{ data.growthStatus }}</h3></td>
                            </tr>
                        </table>
                    </div>
                </li>
            </ul>
            <div v-if="loading" class="loading-indicator">Loading...</div>
        </div>
        <div v-else-if="flagActive == 4">
            <tr>
                <td>
                    <div style="overflow-x: auto;">
                        <Line v-if="loaded" :height="300" :width="1200" :data="data" :options="options"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="data-table">
                    <div class="table-row">
                        <div class="table-cell-header">Umur</div>
                        <div class="table-cell" style="border-bottom: none;" v-for="(data, index) in listAges" :key="index"> {{ data }} 
                            <font-awesome-icon v-if="listNote[index] != ''" icon="circle-info" 
                                style="color: #D3555C; margin-left: 5px;" @click="showDialogs(index)" />
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header">Bulan Timbang</div>
                        <div class="table-cell" style="border-bottom: none;" v-for="data in listBt" :key="data"> {{ data }}</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header">BB (kg)</div>
                        <div class="table-cell" style="border-bottom: none;" v-for="data in listBb"  :key="data"> {{ data }}</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header">KBM (gr)</div>
                        <div class="table-cell" style="border-bottom: none;" v-for="data in listKbm"  :key="data"> {{ data }}</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header">N/T</div>
                        <div class="table-cell" style="border-bottom: none;" v-for="data in listNt"  :key="data"> {{ data }}</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header">ASI Eksklusif</div>
                        <div class="table-cell" v-for="data in listAsi"  :key="data">
                            <font-awesome-icon v-if="data == false" icon="close" />
                            <font-awesome-icon v-else-if="data" icon="check" class="colorCheck" />
                        </div>
                    </div>
                </div>
                </td>
            </tr>
            
        </div>
       

        <div v-else>
            <div style="overflow-x: auto;">
            <Line v-if="loaded" :height="300" :width="1200" :data="data" :options="options"/>
            </div>
        </div>
    </div>
    <v-dialog v-model="isShowDialog" maxWidth="360px" persistent>
        <v-card>
            <v-card-text>
            <div>
                <p style="font-size: 12px; text-align: center; margin-left: 20px; margin-right: 20px;">
                    {{ this.notes }}
                </p>
            </div>
            </v-card-text>
            <v-btn class="custom-btn-next" style="color: #00CC6A;" elevation="0" @click="closeDialog">Oke</v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
    import { ref } from 'vue'
    import ApiService from '@/service/ApiServices.js'
    import axios from 'axios';
    import {
            Chart as ChartJS,
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend
            } from 'chart.js'
    import { Line } from 'vue-chartjs'

    ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend
            )

    export default {
        setup() {
            const dataHasil = ref([])
            var listAges = []
            var listBt = []
            var listBb = []
            var listKbm = []
            var listNt = []
            var listAsi = []
            var listNote = []
            var listPbU = []
            var listBbU = []
            var listBbPb = []
            var listBbTb = []
            return { dataHasil, listAges, 
                listBt, listBb, listKbm, listNt, listAsi, listNote, listPbU, listBbU, 
                listBbPb, listBbTb}
        },
        components: {
            Line
        },
        data() {
            var resData = this.$route.query
            const tempBirthdate = this.formatedBirthDate(resData['birthDate'])
            return {
                flagActive: 0,
                flagFilterAktif: 0,
                indexChart: 1,
                loading: true,
                currentPage: 0,
                isLastPage: false,
                fullName: resData['patientFullName'],
                gender: resData['gender'],
                tglLahir: tempBirthdate,
                healthCenterId: resData['healthCenterId'],
                medicalRecordNumber: resData['medicalRecordNumber'],
                measurementDate: resData['measurementDate'],
                isShowDialog: false,
                totalMonths: 0,
                isShowFilterBbTb: false,
                activeButton: 0,
                listMin2: [],
                listMin3: [],
                listMin1: [],
                listMedian: [],
                listPlus3: [],
                listPlus2: [],
                listPlus1: [],
                notes: '',
                url: '',
                dataToUpdate: {},
                loaded: false,
                data: {
                    labels: Array.from({ length: 61 }, (_, i) => i),
                    datasets: [
                        {
                            label: 'plus3',
                            data: [],
                            tension: 0.1,
                            yAxisID: 'y',
                            pointRadius: 0,
                            borderColor: '#000000',
                            borderWidth: 1.5,
                        },
                        {
                            label: 'plus2',
                            data: [],
                            tension: 0.1,
                            yAxisID: 'y',
                            pointRadius: 0,
                            borderColor: '#D3555C',
                            borderWidth: 1.5,
                        },
                        {
                            label: 'plus1',
                            data: [],
                            tension: 0.1,
                            yAxisID: 'y',
                            pointRadius: 0,
                            borderColor: '#000000',
                            borderWidth: 1.5,
                        },
                        {
                            label: 'median',
                            data: [],
                            tension: 0.1,
                            yAxisID: 'y',
                            pointRadius: 0,
                            borderColor: '#00CC6A',
                            borderWidth: 1.5,
                        },
                        {
                            label: 'min1',
                            data: [],
                            tension: 0.1,
                            yAxisID: 'y',
                            pointRadius: 0,
                            borderColor: '#FE9800',
                            borderWidth: 1.5,
                        },
                        {
                            label: 'min2',
                            backgroundColor: '#f87979',
                            data: [],
                            tension: 0.1,
                            yAxisID: 'y',
                            pointRadius: 0,
                            borderColor: '#000000',
                            borderWidth: 1.5,
                        },
                        {
                            label: 'min3',
                            data: [],
                            tension: 0.1,
                            yAxisID: 'y',
                            pointRadius: 0,
                            borderColor: '#000000',
                            borderWidth: 1.5,
                        },
                        {
                            label: 'marker',
                            backgroundColor: 'red',
                            data: [{ x: '3', y: 67 }],
                            yAxisID: 'y',
                            pointRadius: 3,
                            borderColor: 'red',
                            borderWidth: 1.5,
                            showLine: false
                        }
                    ],
                },

                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            display: true,
                            title: {
                                display: true,
                                text: 'Usia (bulan penuh)'
                           
                            },
                        },
                        y: {
                            display: true,
                            title: {
                                display: true,
                                text: 'Berat Badan (kg)',
                            },
                            min: 45,
                            max: 130,
                        },
                         y1: {
                            type: 'linear',
                            display: false,
                            position: 'right',
                            grid: {
                            drawOnChartArea: false,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            position: 'bottom',
                            display: false,
                        },
                    },
                },
            };
        },  
        methods: {
            changeFlagActive(tabIndex) {
                this.currentPage = 0
                this.isLastPage = false
                this.flagActive = tabIndex;
                this.indexChart = this.flagActive;
                this.isShowFilterBbTb = false;

                if(this.flagActive == 0) {
                    this.getDataHasil()
                } else if (this.flagActive == 4) {
                    this.indexChart = 2
                    // this.flagActive = 2
                    this.getDataKMS()
                    this.fetchDataDummyGrafik()
                } else {
                    if (this.flagActive === 3) {
                        this.isShowFilterBbTb = true;
                    }
                    this.fetchDataDummyGrafik()
                }
            },
            handleFilterClick(index) {
                this.activeButton = index;
                this.flagActive = 3;
                this.flagFilterAktif = index;
                this.fetchDataDummyGrafik()
            },
            redirectToDetail(data) {
                this.$router.push({
                    name: 'detailPemeriksaan',
                    params: {
                        growthMeasurementId: data.growthMeasurementId
                    }
                });
            },
            getDataKMS() {
                this.loading = true;
                ApiService.getDataKMS(this.medicalRecordNumber)
                    .then((response) => {
                        var dataKMS = response.data.data

                        this.listAges = []
                        this.listBt = []
                        this.listBb = []
                        this.listKbm = []
                        this.listNt = []
                        this.listAsi = []
                        this.listNote = []
                        for(var i = 0; i < dataKMS.length; i++) {
                            this.listAges.push(i)
                            if(dataKMS[i] != null) {
                                if(dataKMS[i]['measurementDate'] != null && dataKMS[i]['measurementDate'] != '') {
                                    var dates = new Date(dataKMS[i]['measurementDate'])
                                    let dateFormat = ((dates.getMonth() + 1 > 9) ? (dates.getMonth() + 1) : ('0' + (dates.getMonth() + 1))) + '/' + dates.getFullYear().toString().substring(2)
                                    this.listBt.push(dateFormat)
                                } else {
                                    this.listBt.push('-')
                                }
                                this.listBb.push((dataKMS[i]['weightBodyKg'] != null && dataKMS[i]['weightBodyKg'] != '')? dataKMS[i]['weightBodyKg']: '-')
                                this.listKbm.push((dataKMS[i]['kbmInGr'] != null && dataKMS[i]['kbmInGr'] != '')? dataKMS[i]['kbmInGr']: '-')
                                if(dataKMS[i]['growthStatus'] != null && dataKMS[i]['growthStatus'] != '' && dataKMS[i]['growthStatus'] != '-') {
                                    this.listNt.push((dataKMS[i]['growthStatus'] == 'Tidak')? 'T': 'N')
                                } else {
                                    this.listNt.push('-')
                                }
                                this.listAsi.push((dataKMS[i]['isExclusiveBreastfeeding'] != null)? dataKMS[i]['isExclusiveBreastfeeding']: null)
                                this.listNote.push((dataKMS[i]['note'] != null)? dataKMS[i]['note'] : '')
                            } else {
                                this.listBt.push('-')
                                this.listBb.push('-')
                                this.listKbm.push('-')
                                this.listNt.push('-')
                                this.listAsi.push(null)
                                this.listNote.push('')
                            }
                        }

                        this.loading = false;
                    }).catch(error => {
                        console.error(error)
                        this.loading = false;
                    })
            },
            getDataHasil() {
                ApiService.getDataPemeriksaan(this.currentPage, this.medicalRecordNumber)
                    .then((response) => {
                        this.dataHasil = [];
                        this.listPbU = [];
                        this.listBbPb = [];
                        this.listBbTb = [];
                        this.dataHasil = ref(response.data.data.content);

                        this.currentPage++;
                        this.isLastPage = this.dataHasil.length < 20;

                        
                        this.dataHasil.forEach((item, index) => {
                            const tmpPbU = { x: item.age.totalMonths.toString(), y: item.heightBodyCm };
                            const tmpBbU = { x: item.age.totalMonths.toString(), y: item.weightBodyKg };
                            const tmpBbTb = { 
                                x: item.heightBodyCm.toString().includes('.') ? item.heightBodyCm.toString() : item.heightBodyCm.toString() + '.0', 
                                y: item.weightBodyKg 
                            };

                            this.listPbU.push(tmpPbU);
                            this.listBbU.push(tmpBbU);

                            if (index === 0) {
                                this.totalMonths = item.age.totalMonths;
                            }

                            if (item.age.totalMonths <= 24) {
                                this.listBbPb.push(tmpBbTb);
                            } else {
                                console.log('dataHasil', item.weightBodyKg);
                                this.listBbTb.push(tmpBbTb);
                            }
                        });

                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.loading = false;
                    });
            },
            formatedDate(tanggal) {
                var dates = new Date(tanggal);
                const options = {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                };
                return dates.toLocaleDateString('id-ID', options);
            },
            formatedBirthDate(tanggal) {
                var dates = new Date(tanggal);
                const options = {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                };
                return dates.toLocaleDateString('id-ID', options);
            },
            handleScroll(event) {
                const scrollContainer = event.target;
                if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) { 
                this.loadMoreData();
                }
            },
            async loadMoreData() {
                if(!this.isLastPage) {
                    try {
                        ApiService.getDataPemeriksaan(this.currentPage, this.medicalRecordNumber).then((res) => {
                            this.dataHasil = this.dataHasil.concat(res.data.data.content)
                            this.currentPage++
                            if(res.data.data.content.length < 20) {
                                this.isLastPage = true;
                            } else {
                                this.isLastPage = false;
                            }
                        }).catch(error => {
                            console.error(error)
                        })
                    } catch (error) {
                        console.error('Error loading next page:', error);
                    }   
                }
            },
            closeDialog() {
                this.isShowDialog = false;
            },
            showDialogs(indeks) {
                this.notes = this.listNote[indeks]
                this.isShowDialog = true;
            },
            fetchDataDummyGrafik() {
                this.loaded = false
                const urlMap = {
                    1: this.gender === 'FEMALE' ? '/data_pbu_perempuan.json' : '/data_pbu_pria.json',
                    2: this.gender === 'FEMALE' ? '/data_bbu_perempuan.json' : '/data_bbu_pria.json',
                    3: this.gender === 'FEMALE' ? this.flagFilterAktif === 0 ? 'data_pb_bb_perempuan_0_24.json' : '/data_tb_bb_perempuan.json' : 
                    this.flagFilterAktif === 0 ?  'data_pb_bb_pria_0_24.json' : '/data_tb_bb_pria.json',
                };
                this.url = urlMap[this.indexChart];
                axios.get(this.url).then((response) => {
                const {
                    min3,
                    min2,
                    min1,
                    median,
                    plus1,
                    plus2,
                    plus3,
                } = response.data;
                const dataMin3 = min3;
                const dataMin2 = min2;
                const dataMin1 = min1;
                const dataMedian = median;
                const dataPlus1 = plus1;
                const dataPlus2 = plus2;
                const dataPlus3 = plus3;

                const mapData = (item) => this.flagActive === 3 ? item.bb : item.value;

                this.data.labels = dataMin3.map((item) => this.flagActive === 3 ? item.pb : item.umur);

                this.data.datasets[0].data = dataMin3.map(mapData);
                this.data.datasets[1].data = dataMin2.map(mapData);
                this.data.datasets[2].data = dataMin1.map(mapData);
                this.data.datasets[3].data = dataMedian.map(mapData);
                this.data.datasets[4].data = dataPlus1.map(mapData);
                this.data.datasets[5].data = dataPlus2.map(mapData);
                this.data.datasets[6].data = dataPlus3.map(mapData);

                if (this.flagActive === 1) {
                    this.data.datasets[7].data = this.listPbU;
                } else if (this.flagActive === 3) {
                    if (this.flagFilterAktif === 0) {
                        this.data.datasets[7].data = this.listBbPb;
                    } else {
                        this.data.datasets[7].data = this.listBbTb;
                    }
                } else {
                    this.data.datasets[7].data = this.listBbU;
                }

                this.options.scales.x.title.text = this.flagActive === 3 ? this.flagFilterAktif === 0 ? 'Panjang Badan (cm)' : 'Tinggi Badan' : 'Usia (bulan penuh)';
                this.options.scales.y.title.text = this.flagActive === 1 ? 'Panjang Badan (cm)' : 'Berat Badan (kg)';
                this.options.scales.y.min = this.flagActive === 1 ? 45 : 0;
                this.options.scales.y.max = this.flagActive === 1 ? 130 : 40;
                this.loaded = true;
                });
            },
        },  
        mounted() {
            this.getDataHasil()
            this.getDataKMS()
            this.fetchDataDummyGrafik()
        }
    }
</script>

<style>
    .wrapperHeader {
        display: flex;
        width: 100%;
    }
    .wrapIconHeader {
        align-items: center;
        width: 24px;
        height: 24px;
        margin-top: 2px;
    }
    .header {
        background-color: rgba(243, 241, 239, 0.5);
        width: 100%;
        padding: 10px 20px 10px 20px;
        max-width: 960px;
        margin: 0 auto;
        font-size: .8rem;
    }
    .rowHeaderWrap {
        display: flex;
        width: 100%;
    }
    .rowHeaderWrapImage {
        align-items: center;
        width: 42px;
        height: 42px;
        margin-left: 10px;
    }
    .rowHeaderWrapContent {
        margin-left: 20px;
    }
    .rowHeaderWrapContent li {
        list-style: none; 
    }
    .rowHeaderWrapContent li h4 {
        font-size: 14px;
    }
    .rowHeaderWrapContent li p {
        font-size: 12px;
    }
    .tabWrapper {
        display: flex;
        width: 100%;
        padding: 10px 20px 10px 20px;
        max-width: 960px;
        margin: 0 auto;
        font-size: .8rem;
    }
    .backgroundTab {
        padding: 5px 15px 5px 15px;
        border-radius: 20px;
        border: 1px solid lightgrey;
        color: black;
    }
    .backgroundTab h3 {
        font-size: 12px;
    }
    .backgroundTabActive {
        padding: 5px 15px 5px 15px;
        border-radius: 20px;
        border: 1px solid #2B92E4;
        color: #2B92E4;
    }
    .backgroundTabActive h3 {
        font-size: 12px;
    }
    .dataSection {
        display: flex;
        width: 100%;
        padding: 10px;
        max-width: 960px;
        margin: 0 auto;
        font-size: .8rem;
    }
    .backgroundRowData {
        padding: 10px 10px 10px 20px;
        border-radius: 8px;
        box-shadow: 0 0 4px lightgrey;
        margin-bottom: 10px;
        font-size: 14px;
        max-width: 380px;
    }
    .dataTable, h3 {
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
        text-align: left;
    }
    .colorIcon {
        color: grey;
    }
    .data-table {
        width: 100%;
        padding: 20px 20px 20px 10px;
        overflow-x: auto; /* Enable horizontal scrolling on small screens */
        border-collapse: collapse;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .data-table tr, .data-table td {
        border-right: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        padding: 10px 15px 10px 15px;
        text-align: center;
        color: black;
        font-size: 12px;
    }
    .no-border {
        border-left: none;
    }
    .colorCheck {
        color: #00CC6A;
    }
    .infinite-scroll-list {
        width: 100%;
        min-height: 400px;
        max-height: 750px;
        overflow-y: auto;
        padding: 10px;
    }

    .table-container {
        display: table;
        width: 100%;
    }

    .table-row {
        display: table-row;
    }

    .table-cell {
        display: table-cell;
        min-width: 60px;
        max-width: 60px;
        height: 55px;
        border: 1px solid #ccc;
        text-align: center;
    }

    .table-cell-header {
        display: table-cell;
        padding: 10px 20px 10px 10px;
        min-width: 80px;
        max-width: 80px;
        height: 55px;
        border-right: 1px solid #ccc;
    }

    .custom-btn-next {
        border: 1px solid #00CC6A;
        border-radius: 8px;
        background-color: transparent;
        color: #00CC6A;
        display: block; 
        margin: 10px 45px 20px 45px;
        text-transform: none;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add smooth transitions */
    }
</style>