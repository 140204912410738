<template>
  <input v-model="selectedDate" 
        type="text" placeholder="Select a date"
        readonly
        @click="showDatesPicker"
          style="
        height: 40px;
        width: 100%;
        border: 1px solid lightgrey;
        padding: 5px 10px 5px 10px;
        border-radius: 10px;
        margin-bottom: 2px;
    ">
  <DatePicker v-model="date" v-if="isVisible" @update:model-value="redirectToCalculate(date)" 
    :minDate="mindate" :maxDate="maxdate">
  </DatePicker>
</template>

<script setup>
import { ref } from 'vue'
import { DatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'
import moment from 'moment'

var tempDates = new Date()
const date = ref(new Date(tempDates))
tempDates = moment(date).format('yyyy-MM-DD')
</script>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      isVisible: false,
      selectedDate: moment(new Date()).format('yyyy-MM-DD'),
      mindate: new Date('2023-10-01'),
      maxdate: new Date(),
      disabledDates: ['2023-10-01', '2023-10-02']
    }
  },
  methods: {
    showDatesPicker() {
      this.isVisible = true
    },
    redirectToCalculate(dates) {
      this.selectedDate = moment(new Date(dates)).format('yyyy-MM-DD')
      this.isVisible = false
    },
    isDateDisabled(date) {
      return date < this.mindate;
    },
  }
}
</script>

<style scoped>
  .date-full {
        width: 100%;
        outline: none;
    }
</style>