<template>
    <div class="center-container">
        <div class="loader"></div>
        <br>
        <p class="text-center">Mohon tunggu...</p>
    </div>
</template>

<script>
    import ApiServices from '@/service/ApiServices'
    export default {
        data() {
            return {
                queryParam: '',
                hic: ''
            }
        },
        methods: {
            validateToken(healthCenterId) {
                ApiServices.validateTokenServer(healthCenterId).then((res) => {
                    if(res.data != null && res.data.status == 'OK') {
                        this.$router.push('/dashboard');
                    } else {
                        this.$router.push('/errorPage');
                    }
                }).catch(error => {
                    console.log('error auth == ' + error.message)
                    this.$router.push('/errorPage');
                })
            }
        },
        mounted() {
            this.queryParam = this.$route.query.t
            this.hci = this.$route.query.hci
            localStorage.setItem('authToken', this.queryParam)
            localStorage.setItem('hic', this.hci)
            this.validateToken(this.hci)
        }
    }
</script>

<style scoped>
    .center-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999; /* Ensure it's above other elements */
    }
    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid blue;
        border-right: 16px solid green;
        border-bottom: 16px solid red;
        border-left: 16px solid pink;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .text-center {
        position: absolute; 
        text-align: center;
        font-size: .7rem;
    }
</style>