<template>
    <div class="wrapper">
        <div class="wrapperHeader">
            <div class="wrapIconHeader" @click="$router.go(-1)">
                <font-awesome-icon icon="chevron-left" />
            </div>
            <h3>
                Detail Pemeriksaan
            </h3>
        </div>
    </div>
    <div v-if="!loading">
        <div class="header">
            <div class="rowHeaderWrap">
                <div class="rowHeaderWrapImage">
                    <v-img src="@/assets/default_profile.png" class="imgCenter" alt="" cover></v-img>
                </div>
                <div class="rowHeaderWrapContent">
                    <li>
                        <h4>{{ dataPemeriksaan['patientFullName'] }}</h4>
                        <p v-if="dataPemeriksaan['gender'] == 'MALE'">Laki-Laki - {{ this.formatedDate(dataPemeriksaan['birthDate']) }}</p>
                        <p v-else>Perempuan - {{ this.formatedDate(dataPemeriksaan['birthDate']) }}</p>
                    </li>
                </div>
            </div>
        </div>

        <div style="margin-top: 20px;"></div>

        <div class="container">
            <div class="text-center">
                <button class="checklist-button" @click="openDialog">
                    <div class="icon-container">
                        <v-img src="@/assets/icon_listboard.png" alt="Clipboard" class="clipboard-icon" 
                        width="30px" height="30px" style="display: block; margin: 0 auto;" >
                        </v-img>
                    </div>
                    <span class="button-text">Lihat checklist edukasi pasien</span>
                    <font-awesome-icon :icon="['fas', 'chevron-right']" class="arrow-icon" />
                </button>
            </div>
        </div>

        <div style="margin-top: 20px;"></div>
        
        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Tanggal Pemeriksaan</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan['measurementDate'] }}</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Usia Anak</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 v-if="dataPemeriksaan.age.years > 0 && dataPemeriksaan.age.totalMonths > 0 && dataPemeriksaan.age.months > 0">{{ dataPemeriksaan.age.totalMonths }} Bulan atau {{ dataPemeriksaan.age.years }} Tahun {{ dataPemeriksaan.age.months }} Bulan</h3>
                            <h3 v-else-if="dataPemeriksaan.age.years > 0 && dataPemeriksaan.age.totalMonths > 0 && dataPemeriksaan.age.months <= 0">{{ dataPemeriksaan.age.totalMonths }} Bulan atau {{ dataPemeriksaan.age.years }} Tahun</h3>
                            <h3 v-else>{{ dataPemeriksaan.age.totalMonths }} Bulan</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Berat Badan</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.weightBodyKg }} kg</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item" v-if="dataPemeriksaan.age.totalMonths > 24">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Tinggi Badan</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.heightBodyCm }} cm</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item" v-else>
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Panjang Badan</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.heightBodyCm }} cm</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Nama Ayah</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.fatherName }} kg</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Nama Ibu</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.motherName }} cm</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Lingkar Lengan</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.armCircumferenceCm }} cm</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Lingkar Kepala</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.headCircumferenceCm }} cm</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Kenaikan Berat Minimal (KBM)</td>
                    </tr>
                    <tr>
                        <td v-if="dataPemeriksaan.kbm != null"><h3>{{ dataPemeriksaan.kbm }} g</h3></td>
                        <td v-else><h3>-</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">N/T (Naik atau Tidak)</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 style="color: #18AF7C;" v-if="dataPemeriksaan.growthStatus == 'Naik'">{{ dataPemeriksaan.growthStatus }}</h3>
                            <h3 style="color: #D3555C;" v-else>{{ dataPemeriksaan.growthStatus }}</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Indeks Gizi (BB/U)</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 style="color: #18AF7C;" v-if="dataPemeriksaan.bbPerUmNutritionalStatusCategory == 'BB Normal'">{{ dataPemeriksaan.bbPerUmNutritionalStatusCategory }}</h3>
                            <h3 style="color: #FE9800;" v-else-if="dataPemeriksaan.bbPerUmNutritionalStatusCategory == 'BB Kurang'">{{ dataPemeriksaan.bbPerUmNutritionalStatusCategory }}</h3>
                            <h3 style="color: #D3555C;" v-else>{{ dataPemeriksaan.bbPerUmNutritionalStatusCategory }}</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Indeks Gizi (PB/U)</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 style="color: #18AF7C;" v-if="dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Normal' || dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Tinggi'">{{ dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory }}</h3>
                            <h3 style="color: #FE9800;" v-else-if="dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Pendek'">{{ dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory }}</h3>
                            <h3 style="color: #D3555C;" v-else>{{ dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory }}</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Indeks Gizi (BB/PB)</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 style="color: #18AF7C;" v-if="dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Baik'">{{ dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory }}</h3>
                            <h3 style="color: #FE9800;" v-else-if="dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Kurang' || dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Lebih' || dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Beresiko Gizi Lebih'">{{ dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory }}</h3>
                            <h3 style="color: #D3555C;" v-else>{{ dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory }}</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Asi Eksklusif</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 v-if="dataPemeriksaan.isExclusiveBreastfeeding">Ya</h3>
                            <h3 v-else>Tidak</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Pemberian Vitamin A</td>
                    </tr>
                    <tr>
                        <td>
                            <h3>{{ dataPemeriksaan.vitaminATypeName }}</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Pemberian Imunisasi</td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <ul>
                                <li v-for="immunization in dataPemeriksaan.immunizations" :key="immunization.immunizationType">
                                    {{ immunization.immunizationTypeName }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Nama Obat Cacing</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 v-if="dataPemeriksaan.anthelminticTypeName !== null && dataPemeriksaan.anthelminticTypeName !== ''"> Albendazole </h3>
                            <h3 v-else>-</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Pemberian Obat Cacing</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 v-if="dataPemeriksaan.anthelminticTypeName !== null && dataPemeriksaan.anthelminticTypeName !== ''">{{ dataPemeriksaan.anthelminticTypeName }}</h3>
                            <h3 v-else>-</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <div class="data-table">
                    
                    <div class="table-row">
                        <div class="title-table-cell-header" style="font-size: 17px; text-align: center; vertical-align: center; background-color: #96D5D2;">Umur</div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc; font-size: 14px; font-weight: bold">Bulan</div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="custom-table-last-cell" style="border-bottom: none; border-left: 1px solid #ccc; border-right: none; border-top: none"></div>
                    </div>

                    <div class="table-row">
                        <div class="custom-table-cell-header" style="background-color: #96D5D2;"></div>
                        <div class="table-cell" style="border-bottom: none; border-top: none;  background-color: #96D5D2;" v-for="(data, index) in listMonth" :key="data" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{ data }}</div>
                        <!-- <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc; white-space: nowrap; font-weight: bold;">24-59</div>
                        <div class="table-cell" style="border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div> -->
                    </div>

                    <div class="table-row">
                        <div class="table-cell-header" style= "background-color: #BDCFEB; text-align: center; font-weight: bold;">Jenis Vaksin</div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc; white-space: nowrap; font-weight: bold;">Tanggal Pemberian dan Paraf</div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style="background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                        <div class="table-cell" style= "background-color: #BDCFEB; border-bottom: none; border-left: none; border-right: none; border-top: 1px solid #ccc"></div>
                    </div>

                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;" >Hepatitis B (<24 jam)</div>
                        <div class="table-cell" style="border-bottom: none;" v-for="(data, index) in listHB_0" :style="{ backgroundColor: index === 0 ? '#fff' : '#A8A9AC' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                                v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                                icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>

                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">BCG</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listBCG" :style="{ backgroundColor: index === 0 || index === 1 ? '#fff' : index >= 2 && index <= 10 ? '#FFFF00' : '#A8A9AC' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;" >Polio Tetes 1</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listOPV_1" :style="{ backgroundColor: index === 0 || index === 1 ? '#fff' : index >= 2 && index <= 10 ? '#FFFF00' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                                icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">DPT-HB-Hib 1</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listDPT_HB_HIB_1" :style="{ backgroundColor: index === 0 || index === 1 ? '#A8A9AC' : index == 2 ? '#fff' : index >= 3 && index <= 10 ? '#FFFF00' : '#F36F23' }" :key="index":class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                                v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                                icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                        
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">Polio Tetes 2</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listOPV_2" :style="{ backgroundColor: index === 0 || index === 1 ? '#A8A9AC' : index == 2 ? '#fff' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">Rota Virus (RV) 1*</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listRV_1" :style="{ backgroundColor: index === 0 || index === 1 ? '#A8A9AC' : index == 2 ? '#fff' : index >= 3 && index <= 6 ? '#FFFF00' : '#A8A9AC' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                        <!-- <div class="custom-table-last-cell" style="border-bottom: none; border-left: 1px solid #ccc; border-right: none; border-top: none"></div> -->
                        <div class="custom-table-last-cell" style="border-bottom: none; border-left: 1px solid #ccc; border-right: none; border-top: none"></div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">PCV 1</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listPCV1" :style="{ backgroundColor: index >= 0 && index <= 1 ? '#A8A9AC' : index == 2 ? '#fff' : index >= 3 && index <= 10 ? '#FFFF00' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>

                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">DPT-HB-Hib 2</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listDPT_HB_HIB_2" :style="{ backgroundColor: index >= 0 && index <= 2 ? '#A8A9AC' : index == 3 ? '#fff' : index >= 4 && index <= 10 ? '#FFFF00' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">Polio Tetes 3</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listOPV_3" :style="{ backgroundColor: index >= 0 && index <= 2 ? '#A8A9AC' : index == 3 ? '#fff' : index >= 4 && index <= 10 ? '#FFFF00' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">Rota Virus (RV) 2*</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listRV_2" :style="{ backgroundColor: index >= 0 && index <= 2 ? '#A8A9AC' : index == 3 ? '#fff' : index >= 4 && index <= 6 ? '#FFFF00' : '#A8A9AC' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">PCV 2</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listPCV_2" :style="{ backgroundColor: index >= 0 && index <= 2 ? '#A8A9AC' : index == 3 ? '#fff' : index >= 4 && index <= 10 ? '#FFFF00' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">DPT-HB-Hib 3</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listDPT_HB_HIB_3" :style="{ backgroundColor: index >= 0 && index <= 3 ? '#A8A9AC' : index == 4 ? '#fff' : index >= 5 && index <= 10 ? '#FFFF00' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">Polio Tetes 4</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listOPV_4" :style="{ backgroundColor: index >= 0 && index <= 3 ? '#A8A9AC' : index == 4 ? '#fff' : index >= 5 && index <= 10 ? '#FFFF00' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">Polio Suntik (IPV) 1</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listIPV_1" :style="{ backgroundColor: index >= 0 && index <= 3 ? '#A8A9AC' : index == 4 ? '#fff' : index >= 5 && index <= 10 ? '#FFFF00' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">Rota Virus (RV) 3</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listRV_3" :style="{ backgroundColor: index >= 0 && index <= 3 ? '#A8A9AC' : index == 4 ? '#fff' : index >= 5 && index <= 6 ? '#FFFF00' : '#A8A9AC' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">Campak-Rubella (MR)</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listCAMPAK_RUBELLA_1" :style="{ backgroundColor: index >= 0 && index <= 8 ? '#A8A9AC' : index == 9 ? '#fff' : index == 10 || index == 11 ? '#FFFF00' : '#F36F23' }" :key="index":class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }} 
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">Polio Suntik (IPV) 2</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listIPV_2" :style="{ backgroundColor: index >= 0 && index <= 8 ? '#A8A9AC' : index == 9 ? '#fff' : index == 10 || index == 11 ? '#FFFF00' : '#F36F23' }" :key="index":class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }} 
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">*Japanese Enchepalitis (JE)</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listJE" :style="{ backgroundColor: index >= 0 && index <= 9 ? '#A8A9AC' : index == 10 ? '#fff' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">PCV 3</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listPCV_3" :style="{ backgroundColor: index >= 0 && index <= 11 ? '#A8A9AC' : index == 12 ? '#fff' : index == 13 || index == 14? '#FFFF00' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }} 
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">DPT-HB-Hib lanjutan</div>
                        <div class="table-cell" 
                            style="border-bottom: none;" 
                            v-for="(data, index) in listDPT_HB_Hib_4_LANJUTAN" 
                            :style="{ backgroundColor: index >= 0 && index <= 12 ? '#A8A9AC' : index == 13 ? '#fff' : index == 14 ? '#FFFF00' : '#F36F23' }" 
                            :key="index" 
                            :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }">
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation"  :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell-header" style="background-color: #BDCFEB; border-color: #8692A6;">Campak-Rubella (MR) lanjutan</div>
                        <div class="table-cell" style="border-bottom: none; " v-for="(data, index) in listCAMPAK_RUBELLA_1_LANJUTAN" :style="{ backgroundColor: index >= 0 && index <= 12 ? '#A8A9AC' : index == 13 ? '#fff' : index == 14 ? '#FFFF00' : '#F36F23' }" :key="index" :class="{ 'custom-table-last-cell': index === listMonth.length - 1 }"> {{  }}
                            <font-awesome-icon 
                            v-if="data.split(',')[0] === 'true' && (data.split(',')[1] === undefined || data.split(',')[1].trim() === '')" 
                            icon="check" 
                                class="colorCheck" 
                                :style="{ fontSize: '20px' }" 
                            />
                            <div class="tooltip-container">
                                <font-awesome-icon v-if="data.split(',')[1] && data.split(',')[1].trim() !== ''" icon="exclamation" class="colorCheck" :style="{ fontSize: '20px', color: '#2B92E4' }" />
                                <span class="tooltip-text" v-if="data.split(',')[1]">
                                    {{ data.split(',')[1].trim() }}
                                </span>
                            </div>
                            {{  }}
                        </div>
                    </div>
                    </div>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="7" style="height: 20px;"></td>
                    </tr>
                    <tr>
                        <td style="font-size: 19px;" colspan="7">Keterangan</td>
                    </tr>
                    <tr>
                        <td colspan="7" style="height: 10px;"></td>
                    </tr>
                    <tr>
                        <td style="background-color: #fff; width: 20px; border: 1px solid grey;"></td>
                        <td class="description-color_table">Usia tepat pemberian imunisasi</td>
                    </tr>
                    <tr>
                        <td colspan="7" style="height: 5px;"></td>
                    </tr>
                    <tr>
                        <td style="background-color: #FFF100; width: 20px; height: 20px; border: 1px solid grey;"></td>
                        <td class="description-color_table">Usia yang masih diperbolehkan untuk melengkapi imunisasi bayi dan baduta (bawah dua tahun)</td>
                    </tr>
                    <tr>
                        <td colspan="7" style="height: 5px;"></td>
                    </tr>
                    <tr>
                        <td style="background-color: #F36F23; width: 20px; border: 1px solid grey;"></td>
                        <td class="description-color_table">Usia pemberian imunisasi bayi dan baduta yang belum lengkap (imunisasi kejar)</td>
                    </tr>
                    <tr>
                        <td colspan="7" style="height: 5px;"></td>
                    </tr>
                    <tr>
                        <td style="background-color: #A8A9AC; width: 20px; border: 1px solid grey;"></td>
                        <td class="description-color_table">Usia yang tidak diperbolehkan untuk pemberian imunisasi</td>
                    </tr>
                    <tr>
                        <td colspan="7" style="height: 5px;"></td>
                    </tr>
                    <tr>
                        <td style="background-color: white; width: 20px; border: 1px solid white;">
                            <td class="description-color_table">
                                <font-awesome-icon icon="fas fa-exclamation" style="color: #2B92E4; font-weight: bold; font-size: 16px;" />
                            </td>
                        </td>
                        <td class="description-color_table">Pemberian imunisasi terlambat</td>
                    </tr>
                </table>
            </div>
        </div>
       
    </div>
        
    <div v-else class="loading-indicator">
        <v-img src="@/assets/loading.gif" class="loading-style" alt="Loading..."></v-img>
    </div>
    <br />

    <v-dialog v-model="dialog" max-width="500px" persistent>
        <v-card  rounded="lg">
            <v-card-title class="headline d-flex justify-space-between align-center">
                Edukasi
                <v-btn
                    icon="mdi-close"
                    variant="text"
                    @click="closeDialog"
                    ></v-btn>
            </v-card-title>
            <v-divider class="mb-4"></v-divider>
            <v-card-text>
                <h3 class="subtitle-1 font-weight-bold mb-3">Edukasikan hal berikut:</h3>
                <ul class="education-list">
                <li v-for="(item, index) in educationItems" :key="index">{{ item }}</li>
                </ul>
            </v-card-text>
        </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { ref } from 'vue'
  import ApiServices from '@/service/ApiServices'

  export default {
    setup() {
        const dataPemeriksaan = ref({})
        const listMonth = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 18, 23, '24-59']; 
        const listHB_0 = Array(16).fill('');
        const listBCG = Array(16).fill('');
        const listOPV_1 = Array(16).fill('');
        const listDPT_HB_HIB_1 = Array(16).fill('');
        const listOPV_2 = Array(16).fill('');
        const listRV_1 = Array(16).fill('');
        const listPCV1 = Array(16).fill('');
        const listDPT_HB_HIB_2 = Array(16).fill('');
        const listPCV_2 = Array(16).fill('');
        const listDPT_HB_HIB_3 = Array(16).fill('');
        const listOPV_3 = Array(16).fill('');
        const listOPV_4 = Array(16).fill('');
        const listIPV_1 = Array(16).fill('');
        const listRV_2 = Array(16).fill('');
        const listRV_3 = Array(16).fill('');
        const listCAMPAK_RUBELLA_1 = Array(16).fill('');
        const listIPV_2 = Array(16).fill('');
        const listJE = Array(16).fill('');
        const listPCV_3 = Array(16).fill('');
        const listDPT_HB_Hib_4_LANJUTAN = Array(16).fill('');
        const listCAMPAK_RUBELLA_1_LANJUTAN = Array(16).fill('');

        return { dataPemeriksaan, listMonth, listHB_0, listBCG, 
            listOPV_1, listDPT_HB_HIB_1, listOPV_2, listRV_1, 
            listPCV1, listDPT_HB_HIB_2, listPCV_2, listDPT_HB_HIB_3,listOPV_3,
            listOPV_4, listIPV_1, listRV_3, listCAMPAK_RUBELLA_1, listIPV_2, listRV_2,
            listJE, listPCV_3, listDPT_HB_Hib_4_LANJUTAN, listCAMPAK_RUBELLA_1_LANJUTAN }
    },
    props: {
        value: Boolean
    },
    data() {
        const id = this.$route.params.growthMeasurementId;
        return {    
            dialog: false,
            growthMeasurementId: id,
            loading: true,
            mergeSpan: 2,
            educationItems: []
        };
    },
    methods: {
        getDetailData() {
            ApiServices.getDetailPemeriksaan(this.growthMeasurementId)
                .then((response) => {
                    this.dataPemeriksaan = {}
                    this.dataPemeriksaan = ref(response.data.data)
                    this.loading = false;
                    this.clearList();
                    
                    if (this.dataPemeriksaan.immunizationTable != null) {
                        this.dataPemeriksaan.immunizationTable.forEach((item) => {
                            let index;
                            if (item.ageInTable === 18) {
                                index = 13;
                            } else if (item.ageInTable === 23) {
                                index = 14;
                            } else if (item.ageInTable >= 24 && item.ageInMonth <= 60) {
                                index = 15;
                            } 
                            else {
                                index = this.listMonth.indexOf(item.ageInMonth);
                            }

                            item.immunizationTypes.forEach((type) => {
                                switch (type) {
                                    case 'HB_0':
                                        this.listHB_0[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'BCG':
                                        this.listBCG[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'OPV_1':
                                        this.listOPV_1[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'DPT_HB_HIB_1':
                                        this.listDPT_HB_HIB_1[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'OPV_2':
                                        this.listOPV_2[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'RV_1':
                                        this.listRV_1[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'PCV1':
                                        this.listPCV1[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'DPT_HB_HIB_2':
                                        this.listDPT_HB_HIB_2[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'PCV_2':
                                        this.listPCV_2[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'DPT_HB_HIB_3':
                                        this.listDPT_HB_HIB_3[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'OPV_4':
                                        this.listOPV_4[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'IPV_1':
                                        this.listIPV_1[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'RV_3':
                                        this.listRV_3[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'CAMPAK_RUBELLA_1':
                                        this.listCAMPAK_RUBELLA_1[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'IPV_2':
                                        this.listIPV_2[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'JE':
                                        this.listJE[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'PCV_3':
                                        this.listPCV_3[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'OPV_3':
                                        this.listOPV_3[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'RV_2':
                                        this.listRV_2[index] = 'true,' + (item.note || '');
                                        break;    
                                    case 'DPT_HB_Hib_4_LANJUTAN':
                                        this.listDPT_HB_Hib_4_LANJUTAN[index] = 'true,' + (item.note || '');
                                        break;
                                    case 'CAMPAK_RUBELLA_1_LANJUTAN':
                                        this.listCAMPAK_RUBELLA_1_LANJUTAN[index] = 'true,' + (item.note || '');
                                        break;
                                }
                            });
                        });
                    }
                }).catch(error => {
                    console.log(error)
                    this.loading = false;
                })
        },

        getEducationData() {
            ApiServices.getEducation(this.growthMeasurementId)
                .then((response) => {
                    this.dataPemeriksaan = {}
                    this.dataPemeriksaan = ref(response.data.data)
                    this.loading = false;
                    this.educationItems = this.dataPemeriksaan.education;
                   
                }).catch(error => {
                    console.log(error)
                    this.loading = false;
                })
        },

        formatedDate(tanggal) {
            var dates = new Date(tanggal);
            const options = {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            };
            return dates.toLocaleDateString('id-ID', options);
        },

        clearList() {
            const emptyList = Array(16).fill('');
            this.listHB_0 = [...emptyList];
            this.listBCG = [...emptyList];
            this.listOPV_1 = [...emptyList];
            this.listDPT_HB_HIB_1 = [...emptyList];
            this.listOPV_2 = [...emptyList];
            this.listRV_1 = [...emptyList];
            this.listRV_2 = [...emptyList];
            this.listPCV1 = [...emptyList];
            this.listDPT_HB_HIB_2 = [...emptyList];
            this.listPCV_2 = [...emptyList];
            this.listDPT_HB_HIB_3 = [...emptyList];
            this.listOPV_3 = [...emptyList];
            this.listOPV_4 = [...emptyList];
            this.listIPV_1 = [...emptyList];
            this.listRV_3 = [...emptyList];
            this.listCAMPAK_RUBELLA_1 = [...emptyList];
            this.listIPV_2 = [...emptyList];
            this.listJE = [...emptyList];
            this.listPCV_3 = [...emptyList];
            this.listDPT_HB_Hib_4_LANJUTAN = [...emptyList];
            this.listCAMPAK_RUBELLA_1_LANJUTAN = [...emptyList];
        },

        closeDialog() {
            this.dialog = false;
        },
        openDialog() {
            this.dialog = true;
        },
    },
    mounted() {
        this.getDetailData()
        this.getEducationData()
    },
  };
  </script>
  
  <style scoped>
  .loading-indicator {
    text-align: center;
    margin-top: 150px;
    padding: 10px;
    color: #888;
  }

  .loading-style {
    height: 50px;
    width: 50px;
    display: inline-flex;
  }

  .grid-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 960px;
    margin: 0 auto;
    font-size: .8rem;
    overflow-y: auto;
  }
  
  .grid-item {
    flex: 1;
    min-width: 300px; /* Adjust as needed */
    padding-left: 10px;
    padding-right: 10px;
    font-size: .8rem;
  }

  .input-container {
        width: 100%;
        display: flex;
        border: 1px solid lightgrey;
        background-color: rgba(243, 241, 239, 0.5);
        border-radius: 10px;
        overflow: hidden;
    }
    .input-parent-container {
        width: 100%;
        display: flex;
        overflow: hidden;
    }
    .input-container-other {
        width: 92%;
        display: flex;
        border: 1px solid lightgrey;
        background-color: rgba(243, 241, 239, 0.5);
        border-radius: 10px;
        overflow: hidden;
    }
    .input-container-satuan {
        width: 8%;
        display: flex;
        overflow: hidden;
        padding-left: 5px;
        margin-top: 10px;
    }
    .input-container-radio {
        width: 45%;
        display: inline-flex;
        border: 1px solid lightgrey;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        padding: 8px;
        margin-right: 10px;
    }
    .radio-style {
        margin-top: 0px;
    }

    .radio-style:checked:disabled + label {
        accent-color: red;
    }

    .input-container-radio p {
        margin: 0px 8px 0px 8px;
        padding-top: 2px;
    }
    .background-input {
        width: 100%;
        padding: 8px 10px 8px 15px;
        font-size: 14px;
        border: none;
        background-color: rgba(243, 241, 239, 0.5);
    }
    .background-input:focus {
        border: none;
        outline: none;
    }

    .input-icon {
        border: none;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(243, 241, 239, 0.5);
    }

    .icon-color-primary {
        color: #2B92E4;
    }

    .table-container {
        display: table;
        width: 100%;
    }

    .table-column {
        display: table-column;
    }

    .table-row {
        display: table-row;
    }

    .table-header {
        display: table-cell;
        min-width: 30px;
        max-width: 30px;
        height: 5px;
        border: 1px solid #ccc;
        text-align: center;
    }

    .table-cell {
        display: table-cell;
        min-width: 30px;
        max-width: 30px;
        height: 3px;
        border: 1px solid #ccc;
        border-color: #8692A6;;
        text-align: center;
    }

    .table-cell-header {
        display: table-cell;
        padding: 10px 20px 10px 10px;
        min-width: 200px;
        max-width: 200px;
        height: 10px;
        border-color: #8692A6;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc; 
    }

    .title-table-cell-header {
        display: table-cell;
        padding: 10px 0px 0px 0px;
        min-width: 200px;
        max-width: 200px;
        height: 20px;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        font-weight: bold;
    }

    .custom-table-cell-header {
        display: table-cell;
        padding: 10px 20px 10px 10px;
        min-width: 200px;
        max-width: 200px;
        height: 20px;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }

     .custom-table-last-cell {
        display: table-cell;
        padding: 10px 10px 10px 10px;
        min-width: 60px;
        max-width: 60px;
        height: 20px;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }

    .description-color_table {
        padding-left: 5px;
        font-size: 13px
    }

    .tooltip-container {
        position: relative; /* Position relative to the parent */
        display: inline-block; /* Adjust to the size of the icon */
        cursor: pointer; /* Change cursor to indicate interactivity */
    }

    .tooltip-text {
        visibility: hidden; 
        width: 150px; 
        background-color: #fff;
        color: #2E3A59; 
        text-align: center; 
        padding: 8px;
        border-radius: 4px; 
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%; 
        transform: translateX(-50%); 
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); 
        border: 1px solid #ccc; 
        font-size: 12px; 
    }

    .tooltip-text::after {
        content: ''; 
        position: absolute;
        top: 100%; 
        left: 50%; /* Center the arrow */
        margin-left: -5px; /* Offset to center */
        border-width: 5px; 
        border-style: solid;
        border-color: #fff transparent transparent transparent; /* White arrow pointing up */
    }

    .tooltip-container:hover .tooltip-text {
        visibility: visible;
    }

    .checklist-button {
        display: flex;
        align-items: center;
        background-color: #E6F3FF;
        border: 0.5px solid #8692A6;
        border-radius: 15px;
        border-color: #8692A6;
        padding: 10px 10px;
        color: #2B5989;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .checklist-button:hover {
        background-color: #D6EBFF;
    }

    .icon-container {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .clipboard-icon {
        width: 16px;
        height: 16px;
    }

    .button-text {
        flex-grow: 1;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
    }

    .arrow-icon {
        margin-left: 10px;
        width: 14px;
        height: 14px;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;      
    }

    .rounded-dialog {
        border-radius: 60px;
        overflow: hidden;
    }

    .education-list {
        font-size: 13px;
        list-style-type: disc;
        margin-left: 10px;
        padding-left: 10px; /* Padding to ensure bullets are visible */
    }

    .education-list li {
        margin-bottom: 10px;
    }

    @media only screen and (max-width: 640px) {
        .input-container-radio {
            width: 75%;
            display: inline-flex;
            border: 1px solid lightgrey;
            background-color: white;
            border-radius: 10px;
            overflow: hidden;
            padding: 8px;
            margin-right: 10px;
        }
    }
  </style>  