<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500&family=Poppins:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(228, 228, 228);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2b92e4;
}
</style>
