<template>
    <div class="wrapper">
        <div class="wrapperHeader">
            <div class="wrapIconHeader" @click="$router.replace('/tambahPemeriksaan');">
                <font-awesome-icon icon="chevron-left" />
            </div>
            <h3>
                Isi Pemeriksaan
            </h3>
        </div>
    </div>
    <div class="header">
        <div class="rowHeaderWrap">
            <div class="rowHeaderWrapImage">
                <v-img src="@/assets/default_profile.png" class="imgCenter" alt="" cover></v-img>
            </div>
            <div class="rowHeaderWrapContent">
                <li>
                    <h4>{{ name }}</h4>
                    <p v-if="gender == 'MALE'">Laki-Laki - {{ formatedDate(birth) }}</p>
                    <p v-else>Perempuan - {{ formatedDate(birth) }}</p>
                </li>
            </div>
        </div>
    </div>
    <div style="margin-top: 10px;"></div>
    <div>
        <div class="grid-container">
            <div class="grid-item">
            <table style="width: 100%;">
                <tr>
                    <td colspan="3">Tanggal Pemeriksaan</td>
                </tr>
                <tr>
                    <div :style="dynamicStyles">
                        <input v-model="selectedDate" 
                            type="text" placeholder="Select a date"
                            readonly
                            @click="showDatesPicker"
                            @opened="changeStyle"
                            @closed="hideStyle"
                            class="date-full">
                        <button class="icon-button-dates">
                            <font-awesome-icon icon="calendar-alt"
                                style="color: #2B92E4;" />
                        </button>
                        <DatePicker v-model="valuePickerDate" v-if="isVisible" @update:model-value="redirectToCalculate(valuePickerDate)" 
                            :minDate="mindate" :maxDate="maxdate" style="z-index: 9999;">
                        </DatePicker>
                    </div>
                </tr>
            </table>
            </div>
            <div class="grid-item">
            <table style="width: 100%;">
                <tr>
                    <td colspan="3">Usia Anak</td>
                </tr>
                <tr>
                    <div class="input-container">
                        <input type="text" placeholder="Cari nama" disabled v-model="ages" class="background-input-filled">
                        <button class="input-icon-filled" @click="searchTerm = ''"></button>
                    </div>
                </tr>
            </table>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
            <table style="width: 100%;">
                <tr>
                    <td colspan="3">Nama Ayah</td>
                </tr>
                <tr>
                    <div class="input-parent-container">
                        <div class="input-container-other">
                            <input type="text" placeholder="nama ayah" v-model="namaAyah" 
                                class="background-input" @input="handleInputBb" @blur="blurBb">
                            <button class="input-icon" @click="searchTerm = ''">
                            </button>
                        </div>
                    </div>
                </tr>
            </table>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
            <table style="width: 100%;">
                <tr>
                    <td colspan="3">Nama Ibu</td>
                </tr>
                <tr>
                    <div class="input-parent-container">
                        <div class="input-container-other">
                            <input type="text" placeholder="nama ibu" v-model="namaIbu" 
                                class="background-input" @input="handleInputBb" @blur="blurBb">
                            <button class="input-icon" @click="searchTerm = ''">
                            </button>
                        </div>
                    </div>
                </tr>
            </table>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
            <table style="width: 100%;">
                <tr>
                    <td colspan="3">Berat Badan</td>
                </tr>
                <tr>
                    <div class="input-parent-container">
                        <div class="input-container-other">
                            <input type="number" placeholder="0.0" v-model="tfBb" 
                                class="background-input" @input="handleInputBb" @blur="blurBb">
                            <button class="input-icon" @click="searchTerm = ''">
                            </button>
                        </div>
                        <div class="input-container-satuan">kg</div>
                    </div>
                </tr>
            </table>
            </div>
            <div class="grid-item">
            <table style="width: 100%;">
                <tr>
                    <td colspan="3" v-if="totalAges < 24">Panjang Badan</td>
                    <td colspan="3" v-else>Tinggi Badan</td>
                </tr>
                <tr>
                    <div class="input-parent-container">
                        <div class="input-container-other">
                            <input type="number" placeholder="0.0" v-model="tfPb" 
                                class="background-input" @input="handleInputPb" @blur="blurPb">
                            <button class="input-icon" @click="searchTerm = ''">
                            </button>
                        </div>
                        <div class="input-container-satuan">cm</div>
                    </div>
                </tr>
            </table>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Lingkar Lengan (tidak wajib)</td>
                    </tr>
                    <tr>
                        <div class="input-parent-container">
                            <div class="input-container-other">
                                <input type="number" placeholder="0.0" v-model="tfLl" 
                                    class="background-input" @input="handleInputLl" @blur="blurLl">
                                <button class="input-icon" @click="searchTerm = ''">
                                </button>
                            </div>
                            <div class="input-container-satuan">cm</div>
                        </div>
                    </tr>
                </table>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Lingkar Kepala (tidak wajib)</td>
                    </tr>
                    <tr>
                        <div class="input-parent-container">
                            <div class="input-container-other">
                                <input type="number" placeholder="0.0" v-model="tfLk" 
                                    class="background-input" @input="handleInputLk" @blur="blurLk">
                                <button class="input-icon" @click="searchTerm = ''">
                                </button>
                            </div>
                            <div class="input-container-satuan">cm</div>
                        </div>
                    </tr>
                </table>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3" v-if="totalAges > 6">Anak masih diberikan ASI saat ini?</td>
                        <td colspan="3" v-else>Asi Eksklusif</td>
                    </tr>
                    <tr>
                        <div class="input-container-parent">
                            <div class="input-container-parent">
                                <label v-for="(option, index) in options" :key="index" 
                                    class="input-container-radio">
                                    <input
                                        type="radio"
                                        :value="option.value"
                                        v-model="selectedOption"
                                        class="radio-style"
                                    />
                                    <p>{{ option.label }}</p>
                                </label>
                            </div>
                        </div>
                    </tr>
                </table>
            </div>
        </div>

        <div style="margin-top: 10px;"></div>
        <div class="grid-container">
            <div class="grid-item">
                    <div class="flex-container">
                        <div class="title" colspan="3" style="font-size: 16px;">Pemberian Vitamin A</div>
                        <label class="switch">
                            <input type="checkbox" v-model="isToggledVitamin">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
        </div>
        <div style="margin-top: 5px;"></div>
        <div class="grid-container" v-if="isToggledVitamin">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Jenis Vitamin A yang diberikan</td>
                    </tr>
                    <tr>
                        <div class="input-container-parent">
                            <div class="input-container-parent">
                                <label v-for="(vitamin, index) in vitamins" :key="index" 
                                    class="input-container-radio-vitamin">
                                    <input
                                        type="radio"
                                        :value="vitamin.value"
                                        v-model="selectedVitamin"
                                        class="radio-style"
                                        style="margin-right: 5px;margin-top: 10px;"
                                    />
                                    <span>{{ vitamin.label }}</span>
                                    <br />
                                </label>
                            </div>
                        </div>
                    </tr>
                </table>
            </div>
        </div>
        <div style="margin-top: 10px;"></div>


        <div class="grid-container">
            <div class="grid-item">
                    <div class="flex-container">
                        <div class="title" colspan="3" style="font-size: 16px;">Pemberian Imunisasi</div>
                        <label class="switch">
                            <input type="checkbox" v-model="isToggledImunisasi">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
        </div>
        <div style="margin-top: 5px;"></div>
        <div class="grid-container" v-if="isToggledImunisasi">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Jenis imunisasi yang diberikan</td>
                    </tr>
                    <tr>
                        <div class="input-container-parent">
                            <div class="input-container-parent">
                                <label v-for="(dataImunisasi, index) in imunisasi" :key="index" 
                                    class="input-container-radio-vitamin">
                                    <input
                                        type="checkbox"
                                        :value="dataImunisasi.value"
                                        v-model="listCheckedImunisasi"
                                        class="radio-style"
                                        style="margin-right: 5px;margin-top: 10px;"
                                        :disabled="checkData(totalAges, dataImunisasi.value)"
                                    />
                                    <span>{{ dataImunisasi.label }}</span>
                                    <br />
                                </label>
                            </div>
                        </div>
                    </tr>
                </table>
            </div>
        </div>
        <div style="margin-top: 10px;"></div>
        <div class="grid-container">
            <div class="grid-item">
                    <div class="flex-container">
                        <div class="title" colspan="3" style="font-size: 16px;">Pemberian Obat Cacing</div>
                        <label class="switch">
                            <input type="checkbox" v-model="isToggledObatCacing">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
        </div>
        <div style="margin-top: 5px;"></div>
        <div class="grid-container" v-if="isToggledObatCacing">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Nama Obat</td>
                    </tr>
                    <tr>
                        <div class="input-container-parent">
                            <div class="input-container-parent">
                                <label class="input-container-radio-vitamin">
                                    <input
                                        type="radio"
                                        value="Abendazole"
                                        v-model="selectedObatCacing"
                                        class="radio-style"
                                        style="margin-right: 5px;margin-top: 10px;"
                                        name="obatCacing"
                                        :checked="true"
                                    />
                                    <span>Abendazole</span>
                                    <br />
                                </label>
                            </div>
                        </div>
                    </tr>
                </table>
            </div>
        </div>
        <div style="margin-top: 5px;"></div>
        <div class="grid-container" v-if="isToggledObatCacing">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Jenis Obat Cacing yang diberikan</td>
                    </tr>
                    <tr>
                        <div class="input-container-parent">
                            <div class="input-container-parent">
                                <label v-for="(obatCacing, index) in obatCacings" :key="index" 
                                    class="input-container-radio-vitamin">
                                    <input
                                        type="radio"
                                        :value="obatCacing.value"
                                        v-model="selectedObatCacing"
                                        class="radio-style"
                                        style="margin-right: 5px;margin-top: 10px;"
                                    />
                                    <span>{{ obatCacing.label }}</span>
                                    <br />
                                </label>
                            </div>
                        </div>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <br />
    <footer id="footer-wrapper">
      <li style="list-style: none;">
        <div class="containerButtons">
          <button @click="saveData" class="submit-buttons" style="color: white;">
            Simpan Pemeriksaan
          </button>
        </div>
      </li>
    </footer>
    <v-dialog v-model="isShowLoading" maxWidth="480px" persistent>
        <v-card>
            <v-card-title style="text-align: center; margin-top: 10px;">
                <v-img src="@/assets/loading.gif" class="imgCenter" 
                    width="80px" height="80px" style="display: block; margin: 0 auto;" alt="" cover>
                </v-img>
            </v-card-title>
            <v-card-text>
            <div>
                <p style="font-size: 12px; text-align: center; margin: 0px 20px 10px 20px;">
                    Mohon tunggu...
                </p>
            </div>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isShowDialog" maxWidth="480px" persistent>
        <v-card>
            <v-card-title style="text-align: center; margin-top: 10px;">
                <v-img src="@/assets/icon_send.png" class="imgCenter" 
                    width="80px" height="80px" style="display: block; margin: 0 auto;" alt="" cover>
                </v-img>
            </v-card-title>
            <v-card-text>
            <div>
                <p style="font-size: 12px; text-align: center; margin-left: 20px; margin-right: 20px;">
                    Apakah Anda Yakin Akan Menyimpan Hasil Pemeriksaan?
                </p>
            </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn class="custom-btn" @click="closeDialog">Batal</v-btn>
                <v-btn class="custom-btn-success" @click="submitForm">Simpan</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isShowDialogNext" maxWidth="480px" persistent>
        <v-card>
            <v-card-title style="text-align: center; margin-top: 10px;">
                <v-img src="@/assets/icon_success.png" class="imgCenter" 
                    width="80px" height="80px" style="display: block; margin: 0 auto;" alt="" cover>
                </v-img>
            </v-card-title>
            <v-card-text>
            <div>
                <p style="font-size: 12px; text-align: center;">
                    Hasil Penimbangan Berhasil Tersimpan
                </p>
            </div>
            </v-card-text>
            <v-btn class="custom-btn-next-success" elevation="0" @click="submitFormNext">Lihat Hasil Pemeriksaan</v-btn>
            <v-btn class="custom-btn-next" elevation="0" @click="closeDialogNext">Input Pemeriksaan Lain</v-btn>
        </v-card>
    </v-dialog>
    <v-snackbar
        v-model="show"
        :color="color"
        :timeout="timeout"
        :top="top"
        :bottom="bottom"
        :left="left"
        :right="right"
    >
        <p style="text-align: center;">{{ message }}</p>
    </v-snackbar>
    <v-dialog v-model="isShowErrorDialog" maxWidth="360px" persistent>
        <v-card>
            <v-card-text>
            <div>
                <p style="font-size: 12px; text-align: center; margin-left: 20px; margin-right: 20px;">
                    {{ notes }}
                </p>
            </div>
            </v-card-text>
            <v-btn class="custom-btn-next" style="color: #00CC6A;" elevation="0" @click="closeErrorDialog">Oke</v-btn>
        </v-card>
    </v-dialog>
</template>

<script setup>
    import { ref } from 'vue'
    import { DatePicker } from 'v-calendar'
    import 'v-calendar/dist/style.css'
    import moment from 'moment'

    var tempDates = new Date()
    const date = ref(new Date(tempDates))
    tempDates = moment(date).format('yyyy-MM-DD')
</script>

<script>
    import Datepicker from 'vue3-datepicker';
    import ApiServices from '@/service/ApiServices';
    import moment from 'moment'

    export default {
    mounted() {
        this.changeAgePatient(this.selectedDate)
    },
    data() {
      const temps = this.$route.query.dates
      const tempName = this.$route.query.names
      const tempGender = this.$route.query.genders
      const tempBirth = this.$route.query.births
      const tempKpi = this.$route.query.kpis
      const tempMdrs = this.$route.query.mdrs
      var gmi = ''
      var hci = ''
      var bb = ''
      var pb = ''
      var ll = ''
      var lk = ''
      var asi = ''
      var tempVitamins = this.$route.query.vitamins
      var tempObatCacings = this.$route.query.obatCacings
      var namaAyah = ''
      var namaIbu = ''
      var tempListCheckedImunisasi = []

      if (tempVitamins != null || tempVitamins != '') {
        isToggledVitamin = true
      }

      if (tempObatCacings != null || tempObatCacings != '') {
        isToggledObatCacing = true
      }

      if (tempListCheckedImunisasi.length > 0 || tempListCheckedImunisasi != null) {
        isToggledImunisasi = true
      }

      if(this.$route.query.listCheckedImunisasi.length > 0 || this.$route.query.listCheckedImunisasi != null) {
        tempListCheckedImunisasi = this.$route.query.listCheckedImunisasi
      }
      
      if(this.$route.query.gmi != null || this.$route.query.gmi != '') {
        gmi = this.$route.query.gmi
      }
      if(this.$route.query.hci != null || this.$route.query.hci != '') {
        hci = this.$route.query.hci
      }
      if(this.$route.query.bb != null || this.$route.query.bb != '') {
        if(this.$route.query.bb == 0) {
            bb = ''
        } else {
            bb = this.$route.query.bb
        }
      }
      if(this.$route.query.pb != null || this.$route.query.pb != '') {
        if(this.$route.query.pb == 0) {
            pb = ''
        } else {
            pb = this.$route.query.pb
        }
      }
      if(this.$route.query.ll != null || this.$route.query.ll != '') {
        if(this.$route.query.ll == 0) {
            ll = ''
        } else {
            ll = this.$route.query.ll
        }
      }
      if(this.$route.query.lk != null || this.$route.query.lk != '') {
        if(this.$route.query.lk == 0) {
            lk = ''
        } else {
            lk = this.$route.query.lk
        }
      }
      if(this.$route.query.asi != null || this.$route.query.asi != '') {
        asi = this.$route.query.asi
      }

      if(this.$route.query.namaAyah != null || this.$route.query.namaAyah != '') {
        if(this.$route.query.namaAyah == '') {
            namaAyah = ''
        } else {
            namaAyah = this.$route.query.namaAyah
        }
      }

      if(this.$route.query.namaIbu != null || this.$route.query.namaIbu != '') {
        if(this.$route.query.namaIbu == '') {
            namaIbu = ''
        } else {
            namaIbu = this.$route.query.namaIbu
        }
      }

      var tempDates = moment(new Date()).format('yyyy-MM-DD')
      var mnd = moment(new Date(tempBirth)).format('yyyy-MM-DD')
      if(temps != '') {
        tempDates = temps
        mnd = moment(new Date(
                    new Date(tempDates).getFullYear(),
                    new Date(tempDates).getMonth(),
                    1
                )).format('yyyy-MM-DD')
                
                
      }                                           
      return {
        options: [
            { label: 'Ya', value: 'yes' },
            { label: 'Tidak', value: 'no' },
        ],
        vitamins: [
            { label: 'Vitamin A Kapsul Biru 100.000 IU - 1 kapsul', value: 'VIT_A_KAP_BIRU_100000_IU' },
            { label: 'Vitamin A Kapsul Biru 100.000 IU - 2 kapsul', value: 'VIT_A_KAP_BIRU_200000_IU' },
            { label: 'Vitamin A Kapsul Merah 200.000 IU - 1 kapsul', value: 'VIT_A_KAP_MERAH_200000_IU' },
        ],
        imunisasi: [
            { label: 'Hepatitis B (HB 0)', value: 'HB_0' },
            { label: 'BCG', value: 'BCG' },
            { label: 'Polio Tetes 1 (OPV1)', value: 'OPV_1' },
            { label: 'DPT-HB-Hib 1', value: 'DPT_HB_HIB_1' },
            { label: 'Polio Tetes 2 (OPV2)', value: 'OPV_2' },
            { label: 'Rota Virus 1 (RV1)', value: 'RV_1' },
            { label: 'PCV 1', value: 'PCV1' },
            { label: 'DPT-HB-Hib 2', value: 'DPT_HB_HIB_2' },
            { label: 'PCV 2', value: 'PCV_2' },
            { label: 'DPT-HB-Hib 3', value: 'DPT_HB_HIB_3' },
            { label: 'Polio Tetes 4 (OPV4)', value: 'OPV_4' },
            { label: 'Polio Suntik 1 (IPV 1)', value: 'IPV_1' },
            { label: 'Rota Virus 3 (RV3)', value: 'RV_3' },
            { label: 'Campak-Rubella 1 (MR)', value: 'CAMPAK_RUBELLA_1' },
            { label: 'Polio suntik 2 (IPV2)', value: 'IPV_2' },
            { label: 'Japanese Encephalitis (JE)', value: 'JE' },
            { label: 'PCV 3', value: 'PCV_3' },
            { label: 'DPT-HB-Hib 4 Lanjutan', value: 'DPT_HB_Hib_4_LANJUTAN' },
            { label: 'Campak-Rubella 2 Lanjutan (MR)', value: 'CAMPAK_RUBELLA_1_LANJUTAN' },
        ],
        obatCacings: [
            { label: '200 mg (1/2 tab)', value: 'MG_200_1_PER_2_TAB' },
            { label: '400 mg (1 tab)', value: 'MG_400_1_TAB' },
        ],
        name: tempName,
        gender: tempGender,
        birth: tempBirth,
        kpi: tempKpi,
        mdr: tempMdrs,
        selectedOption: (asi)? 'yes': (asi == '')? '': 'false',
        selectedVitamin: tempVitamins,
        selectedObatCacing: tempObatCacings,
        ages: '',   
        tfBb: bb,
        tfPb: pb,
        tfLl: ll,
        tfLk: lk,
        gmi: gmi,
        hci: hci,
        namaAyah: namaAyah,
        namaIbu: namaIbu,
        listCheckedImunisasi: tempListCheckedImunisasi,
        totalAges: 0,
        dynamicStyles: {
            width: '100%',
            height: 'auto',
            'z-index': '999',
        },
        selectedDate: tempDates,
        showDatePicker: true,
        isShowLoading: false,
        isShowDialog: false,
        isShowDialogNext: false,
        datePickerFormat: 'yyyy-MM-dd',
        show: false,
        message: '',
        color: 'info',
        timeout: 6000, 
        top: false,
        bottom: true,
        left: false,
        right: false,
        mindate: mnd,
        maxdate: moment(new Date()).format('yyyy-MM-DD'),
        notes: '',
        isShowErrorDialog: false,
        isVisible: false,
        valuePickerDate: new Date(),
        isToggled: false,
        isToggledVitamin: false,
        isToggledImunisasi: false,
        isToggledObatCacing: false,
       
      };
    },
    components: {
        Datepicker
    },
    methods: {
        checkData(totalAges, valueImunisasi) {
            let isDisabled = true;
            if (totalAges === 0 && valueImunisasi === 'HB_0') {
                isDisabled = false;
            } else if (totalAges <= 11 && valueImunisasi === 'BCG') {
                isDisabled = false;
            } else if (valueImunisasi === 'OPV_1') {
                isDisabled = false;
            } else if (totalAges > 1 && (valueImunisasi === 'PCV1' || valueImunisasi === 'DPT_HB_HIB_1' || valueImunisasi === 'OPV_2')) {
                isDisabled = false;
            } else if (totalAges > 1 && totalAges < 7 && valueImunisasi === 'RV_1') {
                isDisabled = false;
            } else if (totalAges > 2 && (valueImunisasi === 'DPT_HB_HIB_2' || valueImunisasi === 'PCV_2' || valueImunisasi === 'OPV_3')) {
                isDisabled = false;
            } else if (totalAges > 2 && totalAges < 7 && valueImunisasi === 'RV_2') {
                isDisabled = false;
            } else if (totalAges > 3 && (valueImunisasi === 'DPT_HB_HIB_3' || valueImunisasi === 'IPV_1' || valueImunisasi === 'OPV_4')) {
                isDisabled = false;
            } else if (totalAges > 3 && totalAges < 7 && valueImunisasi === 'RV_3') {
                isDisabled = false;
            } else if (totalAges > 8 && (valueImunisasi === 'CAMPAK_RUBELLA_1' || valueImunisasi === 'IPV_2')) {
                isDisabled = false;
            } else if (totalAges > 9 && valueImunisasi === 'JE') {
                isDisabled = false;
            } else if (totalAges > 10 && valueImunisasi === 'PCV_3') {
                isDisabled = false;
            } else if (totalAges > 10 && (valueImunisasi === 'DPT_HB_Hib_4_LANJUTAN' || valueImunisasi === 'CAMPAK_RUBELLA_1_LANJUTAN')) {
                isDisabled = false;
            }
            return isDisabled;
        },
        toggleState() {
            this.toggle = !this.toggle;
        },
        handleInputBb() {
            if(this.tfBb.toString().includes(',')) {
                var splitStr = this.tfBb.toString().split(',')
                if(splitStr[1] == '') {
                    this.tfBb = parseFloat(this.tfBb.toString()).toFixed(1)
                } else {
                    this.tfBb = parseFloat((this.tfBb.toString().slice(0, this.tfBb.toString().length - 1)).toString()).toFixed(1)
                }
            } else if(this.tfBb.toString().includes('.')) {
                var splitStr = this.tfBb.toString().split('.')
                if(splitStr[1] == '') {
                    this.tfBb = parseFloat(this.tfBb.toString()).toFixed(1)
                } else {
                    if(splitStr[1].toString().length > 1) {
                        this.tfBb = parseFloat((this.tfBb.toString().slice(0, this.tfBb.toString().length - 1)).toString()).toFixed(1)
                    }
                }
            }
        },
        blurBb() {
            if(this.tfBb.toString() != '') {
                this.tfBb = parseFloat(this.tfBb.toString()).toFixed(1)
            }
        },
        handleInputPb() {
            if(this.tfPb.toString().includes(',')) {
                var splitStr = this.tfPb.toString().split(',')
                if(splitStr[1] == '') {
                    this.tfPb = parseFloat(this.tfPb.toString()).toFixed(1)
                } else {
                    this.tfPb = this.tfPb.toString().slice(0, this.tfPb.toString().length - 1)
                }
            } else if(this.tfPb.toString().includes('.')) {
                var splitStr = this.tfPb.toString().split('.')
                if(splitStr[1] == '') {
                    this.tfPb = parseFloat(this.tfPb.toString()).toFixed(1)
                } else {
                    if(splitStr[1].toString().length > 1) {
                        this.tfPb = this.tfPb.toString().slice(0, this.tfPb.toString().length - 1)
                    }
                }
            }
        },
        blurPb() {
            if(this.tfPb.toString() != '') {
                this.tfPb = parseFloat(this.tfPb.toString()).toFixed(1)
                if(this.tfPb.toString().includes(',')) {
                    if(this.tfPb.toString().split(',')[1] < 5) {
                        this.tfPb = parseFloat((parseInt(this.tfPb.toString().split(',')[0])).toString()).toFixed(1)
                    } else if (this.tfPb.toString().split(',')[1] > 5) {
                        this.tfPb = parseFloat((parseInt(this.tfPb.toString().split(',')[0]) + 1).toString()).toFixed(1)
                    }
                } else if(this.tfPb.toString().includes('.')) {
                    if(this.tfPb.toString().split('.')[1] < 5) {
                        this.tfPb = parseFloat((parseInt(this.tfPb.toString().split('.')[0])).toString()).toFixed(1)
                    } else if (this.tfPb.toString().split('.')[1] > 5) {
                        this.tfPb = parseFloat((parseInt(this.tfPb.toString().split('.')[0]) + 1).toString()).toFixed(1)
                    }
                }
            }
        },
        handleInputLl() {
            if(this.tfLl.toString() != '') {
                if(this.tfLl.toString().includes(',')) {
                    var splitStr = this.tfLl.toString().split(',')
                    if(splitStr[1] == '') {
                        this.tfLl = parseFloat(this.tfLl.toString()).toFixed(1)
                    } else {
                        this.tfLl = this.tfLl.toString().slice(0, this.tfLl.toString().length - 1)
                    }
                } else if(this.tfLl.toString().includes('.')) {
                    var splitStr = this.tfLl.toString().split('.')
                    if(splitStr[1] == '') {
                        this.tfLl = parseFloat(this.tfLl.toString()).toFixed(1)
                    } else {
                        if(splitStr[1].toString().length > 1) {
                            this.tfLl = this.tfLl.toString().slice(0, this.tfLl.toString().length - 1)
                        }
                    }
                }
            }
        },
        blurLl() {
            if(this.tfLl.toString() != '') {
                this.tfLl = parseFloat(this.tfLl.toString()).toFixed(1)
            }
        },
        handleInputLk() {
            if(this.tfLk.toString() != '') {
                if(this.tfLk.toString().includes(',')) {
                    var splitStr = this.tfLk.toString().split(',')
                    if(splitStr[1] == '') {
                        this.tfLk = parseFloat(this.tfLk.toString()).toFixed(1)
                    } else {
                        this.tfLk = this.tfLk.toString().slice(0, this.tfLk.toString().length - 1)
                    }
                } else if(this.tfLk.toString().includes('.')) {
                    var splitStr = this.tfLk.toString().split('.')
                    if(splitStr[1] == '') {
                        this.tfLk = parseFloat(this.tfLk.toString()).toFixed(1)
                    } else {
                        if(splitStr[1].toString().length > 1) {
                            this.tfLk = this.tfLk.toString().slice(0, this.tfLk.toString().length - 1)
                        }
                    }
                }
            }
        },
        blurLk() {
            if(this.tfLk.toString() != '') {
                this.tfLk = parseFloat(this.tfLk.toString()).toFixed(1)
            }
        },
        redirectToDashboard() {
            this.$router.push('/dashboard')
        },
        saveData() {
            this.validateData()
        },
        changeAgePatient(tanggal) {
            if(tanggal != null) {
                const toDate = new Date(tanggal);
                const fromDate = new Date(this.birth);
                const age = this.calculateAgeOfMonth(fromDate, toDate);
                if(age.years > 0 && age.totalMonths > 0 && age.months > 0) {
                    this.ages = age.totalMonths + ' Bulan atau ' + age.years + ' Tahun ' + age.months + ' Bulan'
                } else if(age.years > 0 && age.totalMonths > 0 && age.months <= 0) {
                    this.ages = age.totalMonths + ' Bulan atau ' + age.years + ' Tahun'
                } else {
                    this.ages = age.totalMonths + ' Bulan'
                }
                this.totalAges = age.totalMonths
            } else {
                this.ages = ''
                this.totalAges = 0
            }
            this.isVisible = false
        },
        calculateAgeOfMonth(fromDate, toDate) {
            var fromYear = fromDate.getFullYear();
            var fromMonth = fromDate.getMonth();
            var toYear = toDate.getFullYear();
            var toMonth = toDate.getMonth();

            var years = toYear - fromYear;
            var months = toMonth - fromMonth;

            if (months < 0) {
                years--;
                months += 12;
            }

            var days = toDate.getDate() - fromDate.getDate();
            if(days >= 30) {
                months++;
            } else if(days < 0) {
                months--;
            }

            if(months < 0) {
                years--;
                months += 12;
            } else if(months > 12) {
                years++;
            } else if(months == 12) {
                years++;
                months = 0;
            }

            return {
                years: years,
                months: months,
                days: days,
                totalMonths: years * 12 + months
            };
        },
        hideDatePicker() {
            this.showDatePicker = false;
        },
        changeStyle() {
            this.dynamicStyles.height = '250px'
        },
        hideStyle() {
            this.dynamicStyles.height = 'auto'
        },
        redirectToCalculate(dates) {
            if(dates == null) {
                this.valuePickerDate = new Date(this.selectedDate)
                this.selectedDate = moment(new Date(this.valuePickerDate)).format('yyyy-MM-DD')
                this.changeAgePatient(this.selectedDate)
            } else {
                this.selectedDate = moment(new Date(dates)).format('yyyy-MM-DD')
                this.changeAgePatient(this.selectedDate)
            }
        },
        formatedDate(tanggal) {
            var dates = new Date(tanggal);
            const options = {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            };
            return dates.toLocaleDateString('id-ID', options);
        },
        closeDialog() {
            this.isShowDialog = false;
        },
        submitForm() {
            this.isShowDialog = false;
            this.postData()
        },
        closeDialogNext() {
            this.isShowDialogNext = false;
            this.$router.replace('/tambahPemeriksaan');
        },
        submitFormNext() {
            this.isShowDialogNext = false;
            this.$router.replace({
                name: 'hasilPemeriksaan',
                query: {
                    healthCenterId: this.hci,
                    medicalRecordNumber: this.mdr,
                    patientFullName: this.name,
                    birthDate: this.birth,
                    gender: this.gender,
                    measurementDate: this.selectedDate
                }
            });
        },
        async postData() {
            this.isShowLoading = true;
            const valBb = Number(this.tfBb.toString())
            const valPb = Number(this.tfPb.toString())
            const immunizations = this.listCheckedImunisasi.map(imunisasi => {
                return {
                    immunizationType: imunisasi
                };
            });
            const params = {
                "medicalRecordNumber": this.mdr,
                "klinisiaPatientId": this.kpi,
                "patientFullName": this.name,
                "birthDate": this.birth,
                "gender": this.gender,
                "measurementDate": moment(new Date(this.selectedDate)).format('yyyy-MM-DD'),
                "weightBodyKg": valBb,
                "heightBodyCm": valPb,
                "armCircumferenceCm": this.tfLl.toString().length <= 0? 0.0: this.tfLl,
                "headCircumferenceCm": this.tfLk.toString().length <= 0? 0.0: this.tfLk,
                "isExclusiveBreastfeeding": this.selectedOption == 'yes'? true: false,
                "fatherName": this.namaAyah,
                "motherName": this.namaIbu,
                "vitaminAType": (this.selectedVitamin != "") ? this.selectedVitamin: null,
                "anthelminticType": (this.selectedObatCacing) ? this.selectedObatCacing: null,
                "immunizations": immunizations

            }
            if(this.gmi != '') {
                ApiServices.updatePemeriksaan(params, this.gmi).then((res) => {
                    if(res.data.status == 'OK') {
                        this.hci = res.data.data.healthCenterId
                        this.mdr = res.data.data.medicalRecordNumber

                        this.isShowLoading = false;
                        this.isShowDialogNext = true;
                    } else {
                        this.isShowLoading = false;
                    }
                }).catch(error => {
                    this.isShowLoading = false;
                    this.notes = error.response.data.errors[0].message;
                    this.isShowErrorDialog = true;
                })
            } else {
                ApiServices.postPemeriksaan(params).then((res) => {
                    if(res.data.status == 'OK') {
                        this.hci = res.data.data.healthCenterId
                        this.mdr = res.data.data.medicalRecordNumber

                        this.isShowLoading = false;
                        this.isShowDialogNext = true;
                    } else {
                        this.isShowLoading = false;
                    }
                }).catch(error => {
                    this.isShowLoading = false;
                    console.log(error.response.data.errors[0].message)
                    this.notes = error.response.data.errors[0].message;
                    this.isShowErrorDialog = true;
                })
            }
        },
        validateData() {
            if(this.selectedDate == '' || this.selectedDate == null) {
                this.showToast('Data tanggal pemeriksaan tidak boleh kosong', 'error')
                return;
            } else if(this.tfBb == '' || this.tfBb == null || this.tfBb == '0' || this.tfBb == 0) {
                this.showToast('Data berat badan tidak boleh kosong', 'error')
                return;
            } else if(this.tfPb == '' || this.tfPb == null || this.tfPb == '0' || this.tfPb == 0) {
                this.showToast('Data panjang badan tidak boleh kosong', 'error')
                return;
            } else if(this.selectedOption == '' || this.selectedOption == null) {
                this.showToast('Data asi eksklusif tidak boleh kosong', 'error')
                return;
            } else if(this.namaAyah == '' || this.namaAyah == null) {
                this.showToast('Data nama ayah tidak boleh kosong', 'error')
                return;
            } else if(this.namaIbu == '' || this.namaIbu == null) {
                this.showToast('Data nama ibu tidak boleh kosong', 'error')
                return;
            } else {
                this.isShowDialog = true;
            }
        },
        showToast(message, color) {
            this.message = message;
            this.color = color;
            this.show = true;
        },
        closeToast() {
            this.show = false;
        },
        closeErrorDialog() {
            this.isShowErrorDialog = false;
        },
        showDatesPicker() {
            this.isVisible = true
        },
    }
  };
</script>

<style scoped>
.custom-divider {
    height: 2px; /* Thickness of the divider */
    background-color: #ccc; /* Color of the divider */
    margin: 20px 0; /* Vertical spacing around the divider */
}
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #00CC6A;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00CC6A;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
  .grid-container {
    display: flex;
    flex-wrap: wrap; /* This ensures items wrap to the next line on smaller screens */
    width: 100%;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 960px;
    margin: 0 auto;
    font-size: .8rem;
    overflow-y: auto;
  }
  
  .grid-item {
    flex: 1;
    min-width: 220px; /* Adjust as needed */
    padding-left: 10px;
    padding-right: 10px;
    font-size: .8rem;
  }

  .input-container {
        width: 100%;
        display: flex;
        border: 1px solid lightgrey;
        border-radius: 10px;
        overflow: hidden;
    }
    .input-parent-container {
        width: 100%;
        display: flex;
        overflow: hidden;
    }
    .input-container-other {
        width: 90%;
        display: flex;
        border: 1px solid lightgrey;
        border-radius: 10px;
        overflow: hidden;
    }
    .input-container-satuan {
        width: 10%;
        display: flex;
        overflow: hidden;
        padding-left: 3px;
        margin-top: 10px;
    }
    .input-container-radio {
        width: 23.5%;
        display: inline-flex;
        border: 1px solid lightgrey;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        padding: 8px;
        margin-right: 10px;
    }
    .input-container-radio-vitamin {
        width: 23.5%;
        background-color: white;
        overflow: hidden;
    }
    .radio-style {
        margin-top: 0px;
    }
    .input-container-radio p {
        margin: 0px 8px 0px 8px;
        padding-top: 2px;
    }
    .background-input {
        width: 100%;
        padding: 8px 10px 8px 15px;
        font-size: 14px;
        border: none;
    }
    .background-input-filled {
        width: 100%;
        padding: 8px 10px 8px 15px;
        font-size: 14px;
        border: none;
        background-color: rgba(243, 241, 239, 0.5);
    }
    .background-input:focus {
        border: none;
        outline: none;
    }

    .input-icon {
        border: none;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .input-icon-filled {
        border: none;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(243, 241, 239, 0.5);
    }
    .icon-color-primary {
        color: #2B92E4;
    }

    .container-custom {
        width: 100%;
        height: auto;
    }

    .custom-datepicker {
        width: 100%; 
        padding: 8px 15px 8px 15px; 
        --vdp-selected-bg-color: #00CC6A;
        --vdp-hover-bg-color: #00CC6A;
    }

    .custom-btn {
        border: 1px solid lightgray;
        border-radius: 8px;
        background-color: transparent;
        color: gray;
        padding: 8px 30px;
        margin: 8px;
        text-transform: none;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add smooth transitions */
    }

    .custom-btn-success {
        background-color: #00CC6A;
        border-radius: 8px;
        color: white;
        padding: 8px 30px;
        margin: 8px;
        text-transform: none;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add smooth transitions */
    }

    .custom-btn-next {
        border: 1px solid #00CC6A;
        border-radius: 8px;
        background-color: transparent;
        color: #00CC6A;
        display: block; margin: 10px 20px 20px 20px;
        text-transform: none;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add smooth transitions */
    }

    .custom-btn-next-success {
        background-color: #00CC6A;
        border-radius: 8px;
        color: white;
        display: block; margin: 20px 20px 0px 20px;
        text-transform: none;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add smooth transitions */
    }

    .container-tanggal {
        height: 40px;
        width: auto;
        border: 1px solid lightgrey;
        border-radius: 10px;
        margin-bottom: 2px;
        padding: 10px 10px 5px 15px;
    }

    #footer-wrapper {
        bottom: 0;
        width: 100%;
        position: relative;
        color: white;
        max-width: 960px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0 auto;
        font-size: .8rem;
        z-index: 0;
    }

    .containerButtons {
        padding: 20px;
    }

    .submit-buttons {
        background-color: #00CC6A;
        color: white;
        border: none;
        padding: 10px 15px;
        border-radius: 20px;
        cursor: pointer;
        width: 100%;
        font-weight: bold;
        font-size: 18sp;
    }

    .icon-button-dates {
        float: right; 
        margin-top: -32px;
        z-index: 9999; 
        margin-right: 15px;
    }

    .date-full {
        width: 100%;
        outline: none;
        border: 1px solid lightgrey;
        border-radius: 10px;
        margin-bottom: 2px;
        padding: 10px 10px 10px 15px;
    }

    .flex-container {
        display: flex;
        justify-content: space-between;
    }

    @media only screen and (max-width: 640px) {
        .input-container-radio {
            width: 45%;
            display: inline-flex;
            border: 1px solid lightgrey;
            background-color: white;
            border-radius: 10px;
            overflow: hidden;
            padding: 8px;
            margin-right: 10px;
        }

        .icon-button-dates {
            float: right; 
            z-index: 9999; 
            margin-top: -32px;
            margin-right: 15px;
        }

        .date-full {
            width: 100%;
            outline: none;
            border: 1px solid lightgrey;
            border-radius: 10px;
            margin-bottom: 2px;
            padding: 10px 10px 10px 15px;
        }
    }
</style>  