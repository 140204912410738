<template>
    <div class="center-container">
      <div class="container-data">
        <v-img src="@/assets/error_image.png" class="image-style" alt=""></v-img>
        <h2>Ada Masalah Dengan Koneksi Akun ePosyandu</h2>
        <p>Silakan muat ulang (refresh) laman eposyandu Anda, login kembali (masukan ID/email dan password) untuk dapat mengakses laman ini.</p>
      </div>
    </div>
</template>

<script>
</script>

<style scoped>
  .center-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900; /* Ensure it's above other elements */
  }
  .container-data {
    width: 100%;
    display: grid;
  }
  .container-data h2 {
    text-align: center;
    margin: 0px 35px 10px 35px;
  }
  .container-data p {
    text-align: center;
    margin: 0px 30px 10px 30px;
  }
  .image-style {
    margin-bottom: 20px;
    width: 100%;
    height: 250px;
  }
</style>